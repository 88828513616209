// "use client";
// Display app store buttons on mobile and QR code on desktop
import QRAppFlyerSVG from "@/components/icons/static/qr-appflyer-svg";
import usePlatformDetector from "@/hooks/usePlatformDetector";
import { formatTextWithLineBreaks } from "@/utils/component-helpers";
import { Entry } from "contentful";
import Image from "next/image";
import { useRef } from "react";

type Options = {
  qrText: string;
};

const AdaptiveAppStoreLink = ({
  data,
  debug,
  options = data.fields.options,
  className,
  ...props
}: {
  data: Entry<any>;
  debug?: any;
  options?: Options;
  className?: string;
}) => {
  const ref = useRef(null);
  const environment = usePlatformDetector();

  const qrText = options?.qrText;

  return (
    <>
      {environment.device === "Desktop" && (
        <div className="relative 2xl:flex items-center gap-x-4">
          <div {...props} ref={ref} className="relative w-fit" id="qr-code">
            {/* Debug framer */}
            {debug}
            <QRAppFlyerSVG />
          </div>
          {qrText && (
            <p className="max-w-xs text-sm mt-2 lg:mt-4">
              {formatTextWithLineBreaks(qrText)}
            </p>
          )}
        </div>
      )}

      {environment.device === "Mobile" && environment.platform === "iOS" && (
        <a
          className=" w-full my-auto flex justify-center lg:inline-block"
          href="https://cmcmarkets.onelink.me/3oZk/"
          ref={ref}
          {...props}
        >
          <Image
            alt="Apple App Store"
            loading="lazy"
            width="160"
            height="49"
            src="https://cdn.cmcmarkets.com/neptune/images/app-store-ios.svg"
          />
        </a>
      )}

      {environment.device === "Mobile" && environment.platform === "Android" && (
        <a
          className=" w-full my-auto flex justify-center lg:inline-block"
          href="https://cmcmarkets.onelink.me/3oZk/"
          ref={ref}
          {...props}
        >
          <Image
            alt="Google Play Store"
            loading="lazy"
            width="160"
            height="49"
            className="my-auto"
            src="https://cdn.cmcmarkets.com/neptune/images/app-store-android.svg"
          />
        </a>
      )}
    </>
  );
};

export default AdaptiveAppStoreLink;
