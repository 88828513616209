import React from "react";

// Takes a string and returns a JSX element with line breaks where applicable.
// Commonly used for custom components which have options JSON.
// Example usage: formatTextWithLineBreaks("Line 1\nLine 2\nLine 3")
export const formatTextWithLineBreaks = (text: string): JSX.Element => (
  <>
    {text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ))}
  </>
);
